// ////////////////////////////////////////////////////////////
// run bugsnag as early as possible
import { bugsnag } from './lib/application';
bugsnag();

// ////////////////////////////////////////////////////////////
// include needed polyfills
import { initPolyfills } from './polyfill';
initPolyfills();

// ////////////////////////////////////////////////////////////
// image lazy loading
import 'lazysizes';

// ////////////////////////////////////////////////////////////
// check if cookies are enabled
// - add the .no-cookies class to <html /> if not
let cookieEnabled = Boolean(navigator.cookieEnabled);

if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
    document.cookie = 'testcookie';
    cookieEnabled = document.cookie.indexOf('testcookie') !== -1;
}

if (!cookieEnabled) {
    document.documentElement.classList.add('no-cookies');
}
