import * as polyfills from '@spinnwerk/polyfills';

function initPolyfills() {
    polyfills.dom();
    polyfills.fetch();
    polyfills.picturefill();
    polyfills.intersectionObserver();
    polyfills.objectFit();

    if (typeof RadioNodeList === 'undefined') {
        (() => {
            function getRadioNodeListValue() {
                for (let i = 0, len = this.length; i < len; i++) {
                    let el = this[i];

                    if (el.checked) {
                        return el.value;
                    }
                }
            }

            function setRadioNodeListValue(value) {
                for (let i = 0, len = this.length; i < len; i++) {
                    let el = this[i];

                    if (el.checked) {
                        el.value = value;
                        return;
                    }
                }
            }

            Object.defineProperty(HTMLCollection.prototype, 'value', {
                get: getRadioNodeListValue,
                set: setRadioNodeListValue,
                configurable: true,
            });
        })();

        window.RadioNodeList = Object.assign(HTMLCollection);
    }
}

export { initPolyfills };
